<template>
  <div>
    <div
      class="container-login"
      :style="{height: screenHeight + 'px'}"
    >
      <div class="content-left">
        <div class="display-flex align-items-center">
          <v-btn
            href="/login"
            text
          >
            <v-icon
              class="icon-return"
              size="15px"
            >
              mdi-arrow-left
            </v-icon>
            <span class="text-return mon-regular">{{ texts.signin.buttonReturn }}</span>
          </v-btn>
          <v-spacer></v-spacer>
          <div
            @click="home"
            class="content-image hidden-mobile"
          >
            <img
              src="@/assets/logos/logo-bg-white.svg"
              alt="proplat-image.png"
            >
          </div>
        </div>
        <div class="display-flex align-items-center justify-content-center">
          <div
            @click="home"
            class="content-image show-mobile"
          >
            <img
              src="@/assets/logos/logo-bg-white.svg"
              alt="proplat-image.png"
            >
          </div>
        </div>
        <signin-form-layout :texts="texts"></signin-form-layout>
        <select-language-component />
      </div>
      <div class="content-right">
        <signin-background-image-layout></signin-background-image-layout>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SigninView",
  data() {
    return {
      //VARIABLES
      screenHeight: 0,
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.signin[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight;
    },
    home: function () {
      this.$router
        .push({
          name: "Home",
        })
        .catch(() => {});
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.signin[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.show-mobile {
  display: none;
}

.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}

.container-login {
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
}

.content-left {
  width: 45%;
  height: 100%;
  padding: 25px;
  position: relative;
}

.content-right {
  width: 55%;
  height: 100%;
}

.content-image {
  width: 260px;
  height: 45px;
}

.content-image:hover {
  cursor: pointer;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .content-left {
    width: 52%;
  }

  .content-right {
    width: 48%;
  }
}

@media (max-width: 720px) {
  .content-left {
    width: 100%;
    padding: 10px;
  }
  .content-right {
    display: none;
  }
  .hidden-mobile {
    display: none;
  }
  .show-mobile {
    display: block;
  }
  .content-image {
    width: 200px;
    height: 45px;
  }
}
</style>