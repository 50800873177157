const texts = {
  en: {
    createdispute: {
      textTitle: 'Make a disputes',
      textResponseLimitImages: 'Only a maximum of 15 images can be selected.',
      textLabelImage: 'Click to <br/> add image',
      textDisputeCategory: 'Disputes category',
      textDisputeSubcategory: 'Disputes Subcategory',
      textComments: 'Comments',
      buttonCancel: 'Cancel',
      buttonConfirm: 'Confirm',
    }
  },
  sp: {
    createdispute: {
      textTitle: 'Comenzar disputa',
      textResponseLimitImages: 'Solo se pueden seleccionar como maximo 15 imagenes.',
      textLabelImage: 'Da click para <br/> añadir imagen',
      textDisputeCategory: 'Categoría de la disputa',
      textDisputeSubcategory: 'Subcategoría de la disputa',
      textComments: 'Comentarios',
      buttonCancel: 'Cancelar',
      buttonConfirm: 'Confirmar',
    }
  }
}

export default texts;