var texts = {
  en: {
    vendor: {
      textTitle: 'Suppliers',
      textSearch: 'Search',
      textEmpty: 'At the moment there are no suppliers available.',
      textFinishedOrders: 'Orders completed',
      textResponseTime: 'Response time',
    },
    vendorDetail: {
      textDescription: 'Description',
      textAttention: 'Business hours schedule',
      textReturn: 'Back',
      tab: {
        generalInformation: 'General information',
        products: 'Items',
      },
      infoVendor: {
        responseTime: 'Average response time',
        orderComplete: 'Orders completed',
        registrationDate: 'Registration date to the platform',
        country: 'Country',
        state: 'State',
        city: 'City',
      }
    }
  },
  sp: {
    vendor: {
      textTitle: 'Proveedores',
      textSearch: 'Buscar',
      textEmpty: 'Por el momento no hay proveedores disponibles.',
      textFinishedOrders: 'Pedidos concretados',
      textResponseTime: 'Tiempo de respuesta',
    },
    vendorDetail: {
      textDescription: 'Descripción',
      textAttention: 'Horarios de atención',
      textReturn: 'Regresar',
      tab: {
        generalInformation: 'Información general',
        products: 'Artículos',
      },
      infoVendor: {
        responseTime: 'Tiempo de respuesta promedio',
        orderComplete: 'Pedidos concretados',
        registrationDate: 'Fecha de registro a la plataforma',
        country: 'País',
        state: 'Estado',
        city: 'Ciudad',
      }
    }
  }
};

export default texts;