<template>
  <div>
    <navbar-component />
    <div class="bg-container">
      <catalog-searchbar-layout :texts="texts"></catalog-searchbar-layout>
      <catalog-content-layout :texts="texts"></catalog-content-layout>
      <div class="push"></div>
    </div>
    <footer class="footer">
      <footer-component />
    </footer>
    <whatsapp-component />
  </div>
</template>

<script>
export default {
  name: "CatalogView",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.catalog[this.selectLanguage];
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.catalog[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  min-height: 82.5%;

  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 0px;
  padding-top: 75px;
}
.footer,
.push {
  height: auto;
}
</style>