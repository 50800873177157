var texts = {
  en: {
    catalog: {
      buttonReturn: 'Back',
      titleSection: 'Categories',
      textCatalogType: 'Category type',
      textSearch: 'Search',
      textEmptyContent: 'Sorry, there are no items in this category yet.'
    }
  },
  sp: {
    catalog: {
      buttonReturn: 'Regresar',
      titleSection: 'Categorías',
      textCatalogType: 'Tipo de Categoría',
      textSearch: 'Buscar',
      textEmptyContent: 'Lo sentimos, todavía no hay elementos dentro <br/> de esta categoría.'
    }
  }
};

export default texts;