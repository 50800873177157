var texts = {
  en: {
    warehouse: {
      tab1: {
        list: {
          name: 'Warehouse name',
          address: 'Address',
          country: 'Country',
          state: 'State',
          city: 'City',
          zipCode: 'Postal code',
          phone: 'Warehouse phone number',
          email: 'Email warehouse',
          repsonsable: 'Warehouse manager',
        },
        buttons: {
          cancel: 'Cancel',
          return: 'Back',
          modify: 'Modify',
          save: 'Save'
        }
      },
      buttons: {
        delete: 'Delete',
        modify: 'Modify',
        return: 'Back',
        cancel: 'Cancel',
        save: 'Save',
      },
      menu: {
        textFavorite: 'Favorite',
        textEdit: 'Edit',
        textDelete: 'Delete'
      },
      dialogAddDocument: {
        textTitle: 'Add document',
        textDocumentName: 'Document name',
        textDocumentLabelFile: 'Click to add document',
        textButtonClose: 'Close',
        textButtonAdd: 'Save'
      },
      tableAttachments: {
        headers: ['Name', 'Document', 'Actions']
      },
      attachments: {
        dragFile: 'Drag a file',
        selectFile: 'Select a file',
        or: 'or',
        textNoDocuments: 'Sin documentos',
        buttonClose: 'Cerrar',
        buttonAddWarehouse: 'Crear almacén',
        buttonAddDocument: 'Add document',
      },
      deleteTextDocument: 'warehouse document',
      textTitleDelete: 'warehouse',
      delete: 'Delete',
      wiewDetail: 'View detail',
      emptyText: 'There are no registered warehouses yet.',
      attachments: {
        dragFile: 'Drag a file',
        selectFile: 'Select a file',
        or: 'or',
        textNoDocuments: 'Sin documentos',
        buttonClose: 'Cerrar',
        buttonAddWarehouse: 'Crear almacén',
        buttonAddDocument: 'Add document',
      },
      photographs: {
        textName: 'Name',
        textActions: 'Actions',
        textNoDocuments: 'Without documents',
        buttonClose: 'Close',
        buttonAddWarehouse: 'Add warehouse',
      },
      textTitle: 'Warehouse',
      buttonAddWarehouse: 'Add warehouse',
      addWarehouse: {
        generalInformation: 'General information',
        attachments: 'Attachments',
        images: 'Photographs',
        buttonReturn: 'Back',
        textTitle: 'New warehouse',
        warehouseName: {
          label: 'Warehouse name',
          placeholder: 'Warehouse name',
        },
        wahrehouseAddress: {
          label: 'Address',
          placeholder: 'Address',
        },
        warehouseCountry: {
          label: 'Country',
          placeholder: 'Country',
        },
        warehouseState: {
          label: 'State',
          placeholder: 'State',
        },
        warehouseCity: {
          label: 'City',
          placeholder: 'City',
        },
        warehousePostalCode: {
          label: 'Postal code',
          placeholder: 'Postal code',
        },
        warehousePhoneNumber: {
          label: 'Warehouse phone number',
          placeholder: 'Warehouse phone number',
        },
        warehouseEmail: {
          label: 'Email warehouse',
          placeholder: 'Email warehouse',
        },
        reponsableWarehouse: {
          label: 'Warehouse manager',
          placeholder: 'Warehouse manager',
        },
        warehouseLatitude: {
          label: 'Latitude',
          placeholder: 'Latitude',
        },
        warehouseLongitude: {
          label: 'Longitude',
          placeholder: 'Longitude',
        },
        warehouseComments: {
          label: 'Comments',
          placeholder: 'Comments',
        },
        buttonClose: 'Close',
        buttonAddWarehouse: 'Add warehouse',
      },
      buttonSaveChangeWarehouse: 'Save',
    }
  },
  sp: {
    warehouse: {
      tab1: {
        list: {
          name: 'Nombre del almacén',
          address: 'Dirección',
          country: 'País',
          state: 'Estado',
          city: 'Ciudad',
          zipCode: 'Código postal',
          phone: 'Teléfono del almacén',
          email: 'Correo del almacén',
          repsonsable: 'Responsable del almacén',
        },
        buttons: {
          cancel: 'Cancelar',
          return: 'Regresar',
          modify: 'Modificar',
          save: 'Guardar'
        }
      },
      buttons: {
        delete: 'Eliminar',
        modify: 'Modificar',
        return: 'Regresar',
        cancel: 'Cancelar',
        save: 'Guardar',
      },
      menu: {
        textFavorite: 'Favorito',
        textEdit: 'Editar',
        textDelete: 'Eliminar'
      },
      dialogAddDocument: {
        textTitle: 'Añadir documento',
        textDocumentName: 'Nombre del documento',
        textDocumentLabelFile: 'Da click para agregar el documento',
        textButtonClose: 'Cerrar',
        textButtonAdd: 'Guardar'
      },
      tableAttachments: {
        headers: ['Nombre', 'Documento', 'Acciones']
      },
      attachments: {
        buttonAddDocument: 'Añadir documento',
        textName: 'Nombre',
        textActions: 'Acciones',
        textNoDocuments: 'Sin documentos',
        buttonClose: 'Cerrar',
        buttonAddWarehouse: 'Crear almacén',
      },
      deleteTextDocument: 'documento de almacén',
      textTitleDelete: 'almacén',
      delete: 'Eliminar',
      wiewDetail: 'Ver detalle',
      emptyText: 'Aún no hay almacenes registrados.',
      attachments: {
        buttonAddDocument: 'Añadir documento',
        textName: 'Nombre',
        textActions: 'Acciones',
        textNoDocuments: 'Sin documentos',
        buttonClose: 'Cerrar',
        buttonAddWarehouse: 'Crear almacén',
      },
      photographs: {
        dragFile: 'Arrastra un archivo',
        selectFile: 'Selecciona un archivo',
        or: 'ó',
        textNoDocuments: 'Sin documentos',
        buttonClose: 'Cerrar',
        buttonAddWarehouse: 'Crear almacén',
      },
      textTitle: 'Almacenes',
      buttonAddWarehouse: 'Añadir almacén',
      addWarehouse: {
        generalInformation: 'Información general',
        attachments: 'Documentos',
        images: 'Imagenes',
        buttonReturn: 'Regresar',
        textTitle: 'Nuevo almacén',
        warehouseName: {
          label: 'Nombre del almacén',
          placeholder: 'Nombre del almacén',
        },
        wahrehouseAddress: {
          label: 'Dirección',
          placeholder: 'Dirección',
        },
        warehouseCountry: {
          label: 'País',
          placeholder: 'País',
        },
        warehouseState: {
          label: 'Estado',
          placeholder: 'Estado',
        },
        warehouseCity: {
          label: 'Ciudad',
          placeholder: 'Ciudad',
        },
        warehousePostalCode: {
          label: 'Código postal',
          placeholder: 'Código postal',
        },
        warehousePhoneNumber: {
          label: 'Teléfono del almacén',
          placeholder: 'Teléfono del almacén',
        },
        warehouseEmail: {
          label: 'Correo del almacén',
          placeholder: 'Correo del almacén',
        },
        reponsableWarehouse: {
          label: 'Responsable del almacén',
          placeholder: 'Responsable del almacén',
        },
        warehouseLatitude: {
          label: 'Latitud',
          placeholder: 'Latitud',
        },
        warehouseLongitude: {
          label: 'Longitud',
          placeholder: 'Longitud',
        },
        warehouseComments: {
          label: 'Comentarios',
          placeholder: 'Comentarios',
        },
        buttonClose: 'Cerrar',
        buttonAddWarehouse: 'Crear almacén',
      },
      buttonSaveChangeWarehouse: 'Guardar',
    }
  }
};

export default texts;