const texts = {
  en: {
    disputedetail: {
      buttonReturn: 'Back',
      textTitle: 'Disputes detail',
      textProvider: 'Supplier',
      textOrderDate: 'Requested on',
      textOrderId: 'Sale order',
      textOrderTotal: 'Order total',
      titleSectionOne: {
        textTitle: 'Disputes detail',
        text1: 'Disputes ID',
        text2: 'Final date',
        text3: 'Creation date',
        text4: 'Category',
        text5: 'Subcategory',
        text6: 'In Charge',
        text7: 'To be determined'
      },
      titleSectionTwo: 'Description',
      titleSectionThree: 'Resolution',
      textMouseImage: 'Hover over the image to zoom in.',
      sNotResolutionMessage: "This dispute has not yet been resolved."
    }
  },
  sp: {
    disputedetail: {
      buttonReturn: 'Regresar',
      textTitle: 'Detalle de disputa',
      textProvider: 'Proveedor',
      textOrderDate: 'Pedido el',
      textOrderId: 'Pedido de venta',
      textOrderTotal: 'Total de la orden',
      titleSectionOne: {
        textTitle: 'Detalle de la disputa',
        text1: 'ID de la disputa',
        text2: 'Fecha de finalización',
        text3: 'Fecha de creación',
        text4: 'Categoría',
        text5: 'Subcategoría',
        text6: 'Encargado',
        text7: 'Por asignar'
      },
      titleSectionTwo: 'Descripción',
      titleSectionThree: 'Resolución',
      textMouseImage: 'Pasa el mouse encima de la imagen para aplicar zoom.',
      sNotResolutionMessage: "Esta disputa no ha sido resuelta aún."
    }
  }
}
export default texts;