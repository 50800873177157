var texts = {
  en: {
    viewArticle: {
      textButtonReturn: 'Back',
      textMouseZoom: 'Hover over the image to zoom in.',
      textLinkButtonProductsProvider: 'View supplier items',
      textReviews: 'Reviews',
      textFOB: 'Price FOB McAllen',
      textPrice: 'Price per',
      textLogisticCost: 'Logistic Cost',
      textAddCart: 'Add to cart',
      textTitleAboutArticle: 'Item description',
      description: {
        text1: 'Country',
        text2: 'State',
        text3: 'Storage temperature',
        text4: 'Temperature in transfer',
        text5: 'Packer',
        text6: 'Packing time',
        text7: 'Item pickup availability date',
        text8: 'Warehouse location',
        text9: 'Business hours',
        text10: 'Pieces per boxes',
        text11: 'Weight per box',
        text12: 'Boxes per pallet',
      },
      textNoSession: 'To add items to the shopping cart you have to log in.',
      textMinBoxes: 'The minimum purchase amount for this item is: ',
    },
  },
  sp: {
    viewArticle: {
      textButtonReturn: 'Regresar',
      textMouseZoom: 'Pasa el mouse encima de la imagen para aplicar zoom.',
      textLinkButtonProductsProvider: 'Ver artículos del proveedor',
      textReviews: 'Reseñas',
      textFOB: 'Precio FOB McAllen',
      textPrice: 'Precio de campo',
      textLogisticCost: 'Costo logístico',
      textAddCart: 'Añadir al carrito',
      textTitleAboutArticle: 'Descripción del artículo',
      description: {
        text1: 'País',
        text2: 'Estado',
        text3: 'Temperatura en almacén',
        text4: 'Temperatura en traslado',
        text5: 'Empacadora',
        text6: 'Tiempo de empaque',
        text7: 'Fecha de disponibilidad de recolección del artículo',
        text8: 'Ubicación de almacén',
        text9: 'Horario de atención',
        text10: 'Piezas por caja',
        text11: 'Peso por caja',
        text12: 'Cajas por pallet',
      },
      textNoSession: 'Para agregar articulos al carrito de compras tiene que iniciar sesión.',
      textMinBoxes: 'El monto mínimo de compra para este artículo es de: ',
    },
  }
};

export default texts;