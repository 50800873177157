var texts = {
  en: {
    signin: {
      buttonReturn: 'Back',
      textTitle: 'User register',
      textDescription: 'Select the type of registration that suits your needs and fill out our forms to join the PROPLAT community.',
      textTitleCustomer: 'Buying company',
      textTitleVendor: 'Supplier company',
    }
  },
  sp: {
    signin: {
      buttonReturn: 'Regresar',
      textTitle: 'Registro de usuario',
      textDescription: 'Seleccione el tipo de registro que se adapte a sus necesidades y complete nuestros formularios para unirse a la comunidad PROPLAT.',
      textTitleCustomer: 'Empresa compradora',
      textTitleVendor: 'Empresa proveedora',
    }
  }
};

export default texts;