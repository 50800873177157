import Vue from 'vue'

// --- Components --- //
Vue.component('WhatsappComponent', () => import('@/components/Whatsapp'));
Vue.component('NavbarComponent', () => import('@/components/Navbar'));
Vue.component('NavbarLandingComponent', () => import('@/components/NavbarLanding'));
Vue.component('DeleteComponent', () => import('@/components/Delete'));
Vue.component('FooterComponent', () => import('@/components/Footer'));
Vue.component('FooterLandingComponent', () => import('@/components/FooterLanding'));
Vue.component('MenuComponent', () => import('@/components/Menu'));
Vue.component('LanguageComponent', () => import('@/components/Language'));
Vue.component('ZoomImage', () => import('@/components/ZoomImage'));
Vue.component('NotificationCartComponent', () => import('@/components/NotificationCart'));
Vue.component('CarouselComponent', () => import('vue-owl-carousel'));
Vue.component('GifPlayer', () => import('vue-gifplayer'));
Vue.component('CreateDisputeComponent', () => import('@/components/CreateDispute'));
Vue.component('CloseDialogComponent', () => import('@/components/CloseDialog'));
Vue.component('GalleryComponent', () => import('@/components/Gallery'));
Vue.component('SearchGlobalComponent', () => import('@/components/SearchGlobal'));
Vue.component('SelectLanguageComponent', () => import('@/components/SelectLanguage'));

// --- Layouts Home --- //
Vue.component('HomeBannerLayout', () => import('@/layouts/Welcome/Banner'));
Vue.component('HomeCatalogTypeLayout', () => import('@/layouts/Welcome/CatalogType'));
Vue.component('HomeCatalogTypeSkeletonLayout', () => import('@/layouts/Welcome/CatalogTypeSkeleton'));
Vue.component('HomePopularCatalogLayout', () => import('@/layouts/Welcome/PopularCatalog'));
Vue.component('HomePopularCatalogSkeletonLayout', () => import('@/layouts/Welcome/PopularCatalogSkeleton'));
Vue.component('HomeFeaturedSupplierLayout', () => import('@/layouts/Welcome/FeaturedSupplier'));
Vue.component('HomeFeaturedSupplierSkeletonLayout', () => import('@/layouts/Welcome/FeaturedSupplierSkeleton'));
Vue.component('HomeItemSoldLayout', () => import('@/layouts/Welcome/ItemSold'));
Vue.component('HomeItemSoldSkeletonLayout', () => import('@/layouts/Welcome/ItemSoldSkeleton'));

// --- Layouts Landing --- //
Vue.component('HomeBannerLandingLayout', () => import('@/layouts/Landing/Banner'));
Vue.component('HomeVideoLandingLayout', () => import('@/layouts/Landing/VideoLanding'));
Vue.component('HomeVideoInfoLayout', () => import('@/layouts/Landing/VideoInfo'));
Vue.component('HomeAboutLayout', () => import('@/layouts/Landing/About'));
Vue.component('HomeBuyerLayout', () => import('@/layouts/Landing/Buyer'));
Vue.component('HomeProviderLayout', () => import('@/layouts/Landing/Provider'));

// --- Layouts Login --- //
Vue.component('LoginFormLayout', () => import('@/layouts/Login/Form'));
Vue.component('LoginBackgroundImageLayout', () => import('@/layouts/Login/BackgroundImage'));


// --- layouts catalogs --- //
Vue.component('CatalogSearchbarLayout', () => import('@/layouts/Catalog/Searchbar'));
Vue.component('CatalogContentLayout', () => import('@/layouts/Catalog/Content'));
Vue.component('CatalogSkeletonLayout', () => import('@/layouts/Catalog/Skeleton'));

// --- layouts subcatalogs --- //
Vue.component('SubcatalogSearchbarLayout', () => import('@/layouts/Subcatalog/Searchbar'));
Vue.component('SubcatalogContentLayout', () => import('@/layouts/Subcatalog/Content'));
Vue.component('SubcatalogSkeletonLayout', () => import('@/layouts/Subcatalog/Skeleton'));

// --- layouts articles --- //
Vue.component('ArticleSearchbarLayout', () => import('@/layouts/Article/Searchbar'));
Vue.component('ArticleContentLayout', () => import('@/layouts/Article/Content'));
Vue.component('ArticleSkeletonLayout', () => import('@/layouts/Article/Skeleton'));

// --- layouts view articles --- //
Vue.component('ViewArticleSearchbarLayout', () => import('@/layouts/ViewArticle/Searchbar'));
Vue.component('ViewArticleContentLayout', () => import('@/layouts/ViewArticle/Content'));

// --- Layouts signin --- //
Vue.component('SigninFormLayout', () => import('@/layouts/Signin/Form'));
Vue.component('SigninBackgroundImageLayout', () => import('@/layouts/Signin/BackgroundImage'));

// --- Layouts signin customer --- //
Vue.component('SigninCustomerFormLayout', () => import('@/layouts/SigninCustomer/Form'));
Vue.component('SigninCustomerBackgroundImageLayout', () => import('@/layouts/SigninCustomer/BackgroundImage'));

// --- Layouts signin vendor --- //
Vue.component('SigninVendorFormLayout', () => import('@/layouts/SigninVendor/Form'));
Vue.component('SigninVendorBackgroundImageLayout', () => import('@/layouts/SigninVendor/BackgroundImage'));

// --- Layouts password recovery --- //
Vue.component('PasswordRecoveryFormLayout', () => import('@/layouts/PasswordRecovery/Form'));
Vue.component('PasswordRecoveryBackgroundImageLayout', () => import('@/layouts/PasswordRecovery/BackgroundImage'));

// --- layouts profile --- //
Vue.component('ProfileContentLayout', () => import('@/layouts/Profile/Content'));
Vue.component('ProfileTabContentOneLayout', () => import('@/layouts/Profile/TabContentOne'));
Vue.component('ProfileTabContentTwoLayout', () => import('@/layouts/Profile/TabContentTwo'));
Vue.component('ProfileTabContentThreeLayout', () => import('@/layouts/Profile/TabContentThree'));
Vue.component('ProfileTabContentFourLayout', () => import('@/layouts/Profile/TabContentFour'));
Vue.component('ProfileChangePasswordLayout', () => import('@/layouts/Profile/ChangePassword'));
Vue.component('ProfileAddDocumentLayout', () => import('@/layouts/Profile/AddDocument'));
Vue.component('ProfileAddUserLayout', () => import('@/layouts/Profile/AddUser'));
Vue.component('ProfileEditUserLayout', () => import('@/layouts/Profile/EditUser'));
Vue.component('ProfileDialogEditProfileLayout', () => import('@/layouts/Profile/EditProfile'));

// --- layouts profile --- //
Vue.component('ChangePasswordBackgroundImageLayout', () => import('@/layouts/ChangePassword/BackgroundImage'));
Vue.component('ChangePasswordFormLayout', () => import('@/layouts/ChangePassword/Form'));

// --- layouts warehouse --- //
Vue.component('WarehouseSearchbarLayout', () => import('@/layouts/Warehouse/Searchbar'));
Vue.component('WarehouseContentLayout', () => import('@/layouts/Warehouse/Content'));
Vue.component('WarehouseMenuLayout', () => import('@/layouts/Warehouse/Menu'));

// --- layouts add warehouse --- //
Vue.component('AddWarehouseSearchbarLayout', () => import('@/layouts/AddWarehouse/Searchbar'));
Vue.component('AddWarehouseContentLayout', () => import('@/layouts/AddWarehouse/Content'));
Vue.component('AddWarehouseTabContent1Layout', () => import('@/layouts/AddWarehouse/TabContent1'));
Vue.component('AddWarehouseTabContent2Layout', () => import('@/layouts/AddWarehouse/TabContent2'));
Vue.component('AddWarehouseTabContent3Layout', () => import('@/layouts/AddWarehouse/TabContent3'));

// --- layouts edit warehouse --- //
Vue.component('EditWarehouseSearchbarLayout', () => import('@/layouts/EditWarehouse/Searchbar'));
Vue.component('EditWarehouseContentLayout', () => import('@/layouts/EditWarehouse/Content'));
Vue.component('EditWarehouseTabContent1Layout', () => import('@/layouts/EditWarehouse/TabContent1'));
Vue.component('EditWarehouseTabContent2Layout', () => import('@/layouts/EditWarehouse/TabContent2'));
Vue.component('EditWarehouseTabContent3Layout', () => import('@/layouts/EditWarehouse/TabContent3'));

// --- layouts shopping cart --- //
Vue.component('ShoppingCartSearchbarLayout', () => import('@/layouts/ShoppingCart/Searchbar'));
Vue.component('ShoppingCartContentLayout', () => import('@/layouts/ShoppingCart/Content'));
Vue.component('ShoppingCartDesktopUiLayout', () => import('@/layouts/ShoppingCart/DesktopUi'));
Vue.component('ShoppingCartTabletUiLayout', () => import('@/layouts/ShoppingCart/TabletUi'));

// --- layouts order --- //
Vue.component('OrderContentLayout', () => import('@/layouts/Order/Content'));
Vue.component('OrderTabContentOneLayout', () => import('@/layouts/Order/TabContentOne'));
Vue.component('OrderTabContentTwoLayout', () => import('@/layouts/Order/TabContentTwo'));
Vue.component('OrderTabContentThreeLayout', () => import('@/layouts/Order/TabContentThree'));
Vue.component('OrderFilterLayout', () => import('@/layouts/Order/Content/filter'));

// --- layouts vendor --- //
Vue.component('VendorSearchbarLayout', () => import('@/layouts/Vendor/Searchbar'));
Vue.component('VendorContentLayout', () => import('@/layouts/Vendor/Content'));


// --- layouts vendor detail --- //
Vue.component('VendorDetailContentLayout', () => import('@/layouts/VendorDetail/Content'));
Vue.component('VendorDetailContentTabOneLayout', () => import('@/layouts/VendorDetail/ContentTabOne'));
Vue.component('VendorDetailContentTabTwoLayout', () => import('@/layouts/VendorDetail/ContentTabTwo'));
Vue.component('VendorDetailSearchbarLayout', () => import('@/layouts/VendorDetail/Searchbar'));

// --- layouts payment process --- //
Vue.component('PaymentProcessContentLayout', () => import('@/layouts/PaymentProcess/Content'));
Vue.component('PaymentProcessHistorialCartLayout', () => import('@/layouts/PaymentProcess/HistorialCart'));
Vue.component('PaymentProcessWarehouseLayout', () => import('@/layouts/PaymentProcess/Warehouse'));
Vue.component('PaymentProcessPaymentMethodLayout', () => import('@/layouts/PaymentProcess/PaymentMethod'));

// --- layouts disputes --- //
Vue.component('DisputesContentLayout', () => import('@/layouts/Disputes/Content'));
Vue.component('DisputesSearchbarLayout', () => import('@/layouts/Disputes/Searchbar'));
Vue.component('DisputesContentTabOneLayout', () => import('@/layouts/Disputes/ContentTabOne'));
Vue.component('DisputesContentTabTwoLayout', () => import('@/layouts/Disputes/ContentTabTwo'));

// --- layouts order detail --- //
Vue.component('OrderDetailContentLayout', () => import('@/layouts/OrderDetail/Content'));
Vue.component('OrderDetailRateLayout', () => import('@/layouts/OrderDetail/Rate'));

// --- layouts dispute detail --- //
Vue.component('DisputeDetailContentLayout', () => import('@/layouts/DisputeDetail/Content'));


// --- layouts super buyer --- //
Vue.component('SuperBuyerContentLayout', () => import('@/layouts/SuperBuyer/Content'));

// --- layouts take rate --- //

Vue.component('TakeRateContentLayout', () => import('@/layouts/TakeRate/Content'));

Vue.component('DashboardCardsLayout', () => import('@/layouts/Dashboard/Cards'));
Vue.component('DashboardTableLayout', () => import('@/layouts/Dashboard/Table'));
Vue.component('DashboardModalsLayout', () => import('@/layouts/Dashboard/Modals'));
Vue.component('DashboardHistoryLayout', () => import('@/layouts/Dashboard/History'));
Vue.component('DashboardForwardsLayout', () => import('@/layouts/Dashboard/Forwards'));
