var texts = {
  en: {
    subcatalog: {
      buttonReturn: 'Back',
      titleSection: 'Subcategories',
      textSearch: 'Search',
      textEmptyContent: 'Sorry, there are no items in this subcategory yet.'
    }
  },
  sp: {
    subcatalog: {
      buttonReturn: 'Regresar',
      titleSection: 'Subcategorías',
      textSearch: 'Buscar',
      textEmptyContent: 'Lo sentimos, todavía no hay elementos dentro <br/> de esta subcategoría.'
    }
  }
};

export default texts;