var texts = {
  en: {
    article: {
      textMinBoxes: 'The minimum purchase amount for this item is: ',
      buttonReturn: 'Back',
      titleSection: 'Items',
      textSearch: 'Search',
      textCatalog: 'Category',
      textSubcatalog: 'Subcategory',
      textEmptyContent: 'Sorry, there are no items within <br/> of this item yet.',
      card: {
        textReviews: 'Reviews',
        buttonAdd: 'Add',
        buttonShow: 'View',
        textNumberBoxes: 'Box'
      },
      textNoSession: 'To add items to the shopping cart, you have to log in.      ',
    }
  },
  sp: {
    article: {
      textMinBoxes: 'El monto mínimo de compra para este artículo es de: ',
      buttonReturn: 'Regresar',
      titleSection: 'Artículos',
      textSearch: 'Buscar',
      textCatalog: 'Categoría',
      textSubcatalog: 'Subcategoría',
      textEmptyContent: 'Lo sentimos, todavía no hay elementos dentro <br/> de este artículo.',
      card: {
        textReviews: 'Reseñas',
        buttonAdd: 'Añadir',
        buttonShow: 'Ver',
        textNumberBoxes: 'Caja'
      },
      textNoSession: 'Para agregar articulos al carrito de compras tiene que iniciar sesión.',
    }
  }
};

export default texts;