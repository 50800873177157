const texts = {
  en: {
    disputes: {
      textTitle: 'Disputes',
      tabs: {
        text1: 'In process',
        text2: 'Finalized'
      },
      tableInProcess: [
        {
          text: 'Sales order',
          align: 'text-left',
        },
        {
          text: 'Purchase order',
          align: 'text-left',
        },
        {
          text: 'Supplier',
          align: 'text-left',
        },
        {
          text: 'Status',
          align: 'text-left',
        },
        {
          text: 'Actions',
          align: 'text-center',
        },
      ],
      tableFinalized: [
        {
          text: 'Sale order',
          align: 'text-left'
        },
        {
          text: 'Purchase order',
          align: 'text-left'
        },
        {
          text: 'Supplier',
          align: 'text-left'
        },
        {
          text: 'Actions',
          align: 'text-center'
        }
      ],
      textEmpty: 'At the moment there are no disputes to show.',
    }
  },
  sp: {
    disputes: {
      textTitle: 'Disputas',
      tabs: {
        text1: 'En proceso',
        text2: 'Finalizadas'
      },
      tableInProcess: [
        {
          text: 'Pedido de venta',
          align: 'text-left',
        },
        {
          text: 'Orden de compra',
          align: 'text-left',
        },
        {
          text: 'Proveedor',
          align: 'text-left',
        },
        {
          text: 'Estado',
          align: 'text-left',
        },
        {
          text: 'Acciones',
          align: 'text-center',
        },
      ],
      tableFinalized: [
        {
          text: 'Pedido de venta',
          align: 'text-left'
        },
        {
          text: 'Orden de compra',
          align: 'text-left'
        },
        {
          text: 'Proveedor',
          align: 'text-left'
        },
        {
          text: 'Acciones',
          align: 'text-center'
        }
      ],
      textEmpty: 'Por el momento no hay disputas para mostrar.',
    }
  }
};
export default texts;