<template>
  <div>
    <navbar-component />
    <div
      class="bg-container"
      :style="{ paddingBottom: `${pdbottom}px`}"
    >
      <profile-content-layout :texts="texts"></profile-content-layout>
    </div>
    <footer-component />
    <profile-change-password-layout></profile-change-password-layout>
    <profile-add-document-layout></profile-add-document-layout>
    <profile-add-user-layout></profile-add-user-layout>
    <profile-edit-user-layout></profile-edit-user-layout>
    <delete-component></delete-component>
    <whatsapp-component />
    <profile-dialog-edit-profile-layout />
    <whatsapp-component />

  </div>
</template>

<script>
export default {
  name: "ProfileView",
  data() {
    return {
      //VARIABLES
      texts: "",
      pdbottom: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.profile[this.selectLanguage];
    // let screen = window.innerHeight,
    //   usage = document.getElementsByClassName("bg-container")[0].clientHeight,
    //   res = 0;
    // res = screen - usage;
    // this.pdbottom = res;
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.profile[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 75px;
}
</style>