<template>
    <div>
      <navbar-component />
      <div class="bg-container pb-4">
        <take-rate-content-layout :texts="texts"></take-rate-content-layout>
      </div>
      <footer-component />
      <!-- <whatsapp-component /> -->
    </div>
  </template>

  <script>
  export default {
    name: "TakeRateView",
    data() {
      return {
        //VARIABLES
        texts: "",
      };
    },
    beforeMount() {
      this.texts = FILE.takeRate[this.selectLanguage];
    },
    computed: {
      selectLanguage: function() {
        return this.$store.state.language;
      },
    },
    watch: {
      selectLanguage: function() {
        if (this.selectLanguage) {
          this.texts = FILE.takeRate[this.selectLanguage];
        }
      },
    },
  };
  </script>

  <style scoped>
  .bg-container {
    width: 100%;
    background: #f2f2f4 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-top: 75px;
  }
  </style>
