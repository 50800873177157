<template>
  <div>
    <div
      class="container-login"
      :style="{height: screenHeight + 'px'}"
    >
      <div class="content-left">
        <v-btn
          @click="$router.go(-1)"
          text
        >
          <v-icon
            class="icon-return"
            size="15px"
          >
            mdi-arrow-left
          </v-icon>
          <span class="text-return mon-regular">{{ texts.changepassword.buttonReturn }}</span>
        </v-btn>
        <change-password-form-layout :texts="texts"></change-password-form-layout>
      </div>
      <div class="content-right">
        <change-password-background-image-layout></change-password-background-image-layout>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePasswordView",
  data() {
    return {
      //VARIABLES
      screenHeight: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.changepassword[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.changepassword[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}

.container-login {
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
}

.content-left {
  width: 45%;
  height: 100%;
  padding: 25px;
}

.content-right {
  width: 55%;
  height: 100%;
}

@media (min-width: 601px) and (max-width: 960px) {
  .content-left {
    width: 52%;
  }

  .content-right {
    width: 48%;
  }
}

@media (max-width: 720px) {
  .content-left {
    width: 100%;
  }
  .content-right {
    display: none;
  }
}
</style>