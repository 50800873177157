<template>
  <div>
    <div class="content-responsive" v-if="isResponsive">
      <div :style="{ height: screenHeight }">
        <payment-process-historial-cart-layout
          :texts="texts"
        ></payment-process-historial-cart-layout>
      </div>
      <div :style="{ height: screenHeight }">
        <payment-process-content-layout
          :texts="texts"
        ></payment-process-content-layout>
      </div>
    </div>
    <div v-else class="display-flex align-items-center block-content">
      <div class="bg-container-left" :style="{ height: screenHeight }">
        <payment-process-content-layout
          :texts="texts"
        ></payment-process-content-layout>
      </div>
      <div class="bg-container-right" :style="{ height: screenHeight }">
        <payment-process-historial-cart-layout
          :texts="texts"
        ></payment-process-historial-cart-layout>
      </div>
    </div>
    <whatsapp-component />
  </div>
</template>

<script>
export default {
  name: "PaymentProcessView",
  data() {
    return {
      //VARIABLES
      texts: "",
      screenHeight: 0,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.texts = FILE.paymentprocess[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function() {
      this.screenHeight = window.innerHeight + "px";
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.paymentprocess[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-container-left {
  width: 50%;
  overflow: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.bg-container-right {
  width: 50%;
  overflow: auto;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
}

.content-responsive {
  width: 100%;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 10px;
}

@media (max-width: 960px) {
  .bg-container-left {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .bg-container-right {
    width: 100%;
    background: #f2f2f4 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .block-content {
    display: block;
    width: 100%;
  }
}
</style>
