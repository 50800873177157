var texts = {
  en: {
    profile: {
      tab3: {
        empty: 'There are currently no documents.',
      },
      tab2: {
        list: {
          name: 'Company name',
          legalAddress: 'Legal address company',
          address: 'Address',
          country: 'Country',
          state: 'State',
          city: 'City',
          zipCode: 'Postal code',
          email: 'Email enterprise',
          phone: 'Phone number enterprise',
          paca: 'Licence number PACA',
          blueBook: 'Blue book number',
          rfc: 'Tax Payer Id',
          description: 'Enterprise description',
        },
        buttons: {
          cancel: 'Cancel',
          return: 'Back',
          save: 'Save',
          modify: 'Modify',
        }
      },
      buttonCancel: 'Cancel',
      editForm: {
        textTitle: 'Modify user profile',
        textName: 'Name',
        textLastName: 'Last name',
        textEmail: 'Email',
        textPosition: 'Position',
        textPhone: 'Phone',
        textButtonClose: 'Close',
        textButtonSaveChanges: 'Save'
      },
      form: {
        textName: 'Name',
        textLastName: 'Last name',
        textEmail: 'Email',
        textPosition: 'Job title',
        textPhone: 'Phone'
      },
      dialogChangePassword: {
        textTitle: 'Change of password',
        currenPassword: 'Current password',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
        buttonCancel: 'Cancel',
        buttonSave: 'Save'
      },
      textTitle: 'User profile',
      textsTabs: {
        textUser: 'User data',
        textEnterprise: 'Enterprise data',
        textAttachments: 'Attachments',
        textBuyers: 'Users',
      },
      name: {
        label: 'Name',
        placeholder: 'Name',
      },
      lastName: {
        label: 'Last name',
        placeholder: 'Last name',
      },
      email: {
        label: 'Email',
        placeholder: 'Email',
      },
      position: {
        label: 'Job title',
        placeholder: 'Job title',
      },
      phone: {
        label: 'Phone number',
        placeholder: 'Phone number'
      },
      button: {
        changePassword: 'Change password',
        edit: 'Modify',
        saveChanges: 'Save'
      }
    },
    enterpriseData: {
      enterpriseName: {
        label: 'Company name',
        placeholder: 'Company name',
      },
      enterpriseLegalAddress: {
        label: 'Legal address company',
        placeholder: 'Legal address company',
      },
      address: {
        label: 'Address',
        placeholder: 'Address',
      },
      country: {
        label: 'Country',
        placeholder: 'Country',
      },
      state: {
        label: 'State',
        placeholder: 'State',
      },
      city: {
        label: 'City',
        placeholder: 'City',
      },
      zipCode: {
        label: 'Postal code',
        placeholder: 'Postal code',
      },
      enterpriseEmail: {
        label: 'Email enterprise',
        placeholder: 'Email enterprise',
      },
      enterprisePhone: {
        label: 'Phone number enterprise',
        placeholder: 'Phone number enterprise',
      },
      licencePaca: {
        label: 'Licence number PACA',
        placeholder: 'Licence number PACA',
      },
      blueBook: {
        label: 'Blue book number',
        placeholder: 'Blue book number',
      },
      rfc: {
        label: 'Tax Payer id',
        placeholder: 'Tax Payer id',
      },
      enterpriseDescription: {
        label: 'Enterprise description',
        placeholder: 'Enterprise description',
      },
      latitude: {
        label: 'Latitude',
        placeholder: 'Latitude',
      },
      longitude: {
        label: 'Longitude',
        placeholder: 'Longitude',
      },
      buttonEdit: 'Edit',
      buttonSaveChanges: 'Save',
    },
    attachments: {
      table: {
        headers: [
          {
            text: 'Name',
            align: 'text-left'
          },
          {
            text: 'Company documents',
            align: 'text-center'
          },
          {
            text: 'Actions',
            align: 'text-right'
          },
        ],
      },
      buttonAddDocument: 'Add document',
      selectTypeDocument: 'Select document',
      textFile: 'Select your file by clicking here',
      buttonCancel: 'Cancel',
      buttonAdd: 'Add',
      textTitleDialogDelete: 'document',
    },
    buyers: {
      table: {
        headers: ['First name', 'Last name', 'Email', 'Job title', 'Phone', 'Actions']
      },
      buttonAddBuyer: 'Add user',
      dialogAddUser: {
        textTitle: 'Add user',
        textName: 'Name',
        textLastName: 'Last name',
        textEmail: 'Email',
        textPosition: 'Job title',
        textPhone: 'Cellphone',
        buttonCancel: 'Cancel',
        buttonAdd: 'Add',
        textEmpty: 'There are currently no users related to this company',
        textTitleEdit: 'Modify user',
        buttonEdit: 'Save',
        textTitleDelete: 'user',
      }
    }
  },
  sp: {
    profile: {
      tab3: {
        empty: 'Actualmente no hay documentos.',
      },
      tab2: {
        list: {
          name: 'Nombre de la empresa',
          legalAddress: 'Dirección de la empresa',
          address: 'Dirección',
          country: 'País',
          state: 'Estado',
          city: 'Ciudad',
          zipCode: 'Código postal',
          email: 'Correo de la empresa',
          phone: 'Teléfono de la empresa',
          paca: 'Número de licencia PACA',
          blueBook: 'Número de blue book',
          rfc: 'RFC',
          description: 'Descripción de la empresa',
        },
        buttons: {
          cancel: 'Cancelar',
          return: 'Regresar',
          save: 'Guardar',
          modify: 'Modificar',
        }
      },
      buttonCancel: 'Cancelar',
      editForm: {
        textTitle: 'Modificar perfil de usuario',
        textName: 'Nombre',
        textLastName: 'Apellido',
        textEmail: 'Correo electrónico',
        textPosition: 'Posición',
        textPhone: 'Teléfono',
        textButtonClose: 'Cerrar',
        textButtonSaveChanges: 'Guardar'
      },
      form: {
        textName: 'Nombre',
        textLastName: 'Apellido',
        textEmail: 'Correo electrónico',
        textPosition: 'Posición',
        textPhone: 'Teléfono'
      },
      dialogChangePassword: {
        textTitle: 'Cambio de contraseña',
        currenPassword: 'Contraseña actual',
        newPassword: 'Nueva contraseña',
        confirmNewPassword: 'Confirmar nueva contraseña',
        buttonCancel: 'Cancelar',
        buttonSave: 'Guardar'
      },
      textTitle: 'Perfil de usuario',
      textsTabs: {
        textUser: 'Datos del usuario',
        textEnterprise: 'Datos de la empresa',
        textAttachments: 'Documentos',
        textBuyers: 'Usuarios',
      },
      name: {
        label: 'Nombre',
        placeholder: 'Nombre',
      },
      lastName: {
        label: 'Apellido',
        placeholder: 'Apellido',
      },
      email: {
        label: 'Correo elctrónico',
        placeholder: 'Correo elctrónico',
      },
      position: {
        label: 'Posición',
        placeholder: 'Posición',
      },
      phone: {
        label: 'Número teléfonico',
        placeholder: 'Número teléfonico'
      },
      button: {
        changePassword: 'Cambiar contraseña',
        edit: 'Modificar',
        saveChanges: 'Guardar'
      },
    },
    enterpriseData: {
      enterpriseName: {
        label: 'Nombre de la empresa',
        placeholder: 'Nombre de la empresa',
      },
      enterpriseLegalAddress: {
        label: 'Dirección de la empresa',
        placeholder: 'Dirección de la empresa',
      },
      address: {
        label: 'Dirección',
        placeholder: 'Dirección',
      },
      country: {
        label: 'País',
        placeholder: 'País',
      },
      state: {
        label: 'Estado',
        placeholder: 'Estado',
      },
      city: {
        label: 'Ciudad',
        placeholder: 'Ciudad',
      },
      zipCode: {
        label: 'Código postal',
        placeholder: 'Código postal',
      },
      enterpriseEmail: {
        label: 'Correo de la empresa',
        placeholder: 'Correo de la empresa',
      },
      enterprisePhone: {
        label: 'Teléfono de la empresa',
        placeholder: 'Teléfono de la empresa',
      },
      licencePaca: {
        label: 'Número de licencia PACA',
        placeholder: 'Número de licencia PACA',
      },
      blueBook: {
        label: 'Número de blue book',
        placeholder: 'Número de blue book',
      },
      rfc: {
        label: 'RFC',
        placeholder: 'RFC',
      },
      enterpriseDescription: {
        label: 'Descripción de la empresa',
        placeholder: 'Descripción de la empresa',
      },
      latitude: {
        label: 'Latitud',
        placeholder: 'Latitud',
      },
      longitude: {
        label: 'Longitud',
        placeholder: 'Longitud',
      },
      buttonEdit: 'Editar',
      buttonSaveChanges: 'Guardar',
    },
    attachments: {
      table: {
        headers: [
          {
            text: 'Nombre',
            align: 'text-left'
          },
          {
            text: 'Documentos de la empresa',
            align: 'text-center'
          },
          {
            text: 'Acciones',
            align: 'text-right'
          },
        ],
      },
      buttonAddDocument: 'Agregar documento',
      selectTypeDocument: 'Seleccionar documento',
      textFile: 'Selecciona tu archivo dando click aquí',
      buttonCancel: 'Cancelar',
      buttonAdd: 'Añadir',
      textTitleDialogDelete: 'documento'
    },
    buyers: {
      table: {
        headers: ['Nombre', 'Apellido', 'Correo electrónico', 'Posición', 'Teléfono', 'Acciones']
      },
      buttonAddBuyer: 'Agregar usuario',
      dialogAddUser: {
        textTitle: 'Añadir usuario',
        textName: 'Nombre',
        textLastName: 'Apellido',
        textEmail: 'Correo electrónico',
        textPosition: 'Posición',
        textPhone: 'Télefono',
        buttonCancel: 'Cancelar',
        buttonAdd: 'Añadir',
        textEmpty: 'Actualmente no hay usuarios relacionados con esta empresa',
        textTitleEdit: 'Modificar usuario',
        buttonEdit: 'Guardar',
        textTitleDelete: 'usuario',
      }
    }
  }
};

export default texts;