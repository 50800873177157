var texts = {
  en: {
    notificationcart: {
      textTitleNotification: 'Just added to your shopping cart',
      buttonShowShoppinCart: 'See the shopping cart',
      textReviews: 'Reviews'
    },

  },
  sp: {
    notificationcart: {
      textTitleNotification: 'Recién agregado a tu carrito de compra',
      buttonShowShoppinCart: 'Ver carrito',
      textReviews: 'Reseñas'
    },

  }
};

export default texts;