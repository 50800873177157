<template>
  <div>
    <navbar-component />
    <div class="bg-container">
      <edit-warehouse-searchbar-layout
        :texts="texts"
        :arr="arr"
      ></edit-warehouse-searchbar-layout>
      <edit-warehouse-content-layout
        :texts="texts"
        :arr="arr"
      ></edit-warehouse-content-layout>
    </div>
    <footer-component />
    <delete-component />
  </div>
</template>

<script>
export default {
  name: "EditWarehouseView",
  data() {
    return {
      //VARIABLES
      texts: "",
      arr: {},
    };
  },
  beforeMount() {
    this.texts = FILE.warehouse[this.selectLanguage];
    this.getWarehouseId();
  },
  methods: {
    getWarehouseId() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.$route.params.id}`,
        config
      )
        .then((response) => {
          this.arr = response.data.results;

        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.warehouse[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 60px;
}
</style>