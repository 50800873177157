<template>
  <div>
    <div>
      <navbar-component />
      <div class="bg-container">
        <div class="mb-5 mt-5">
          <p class="text-title mon-bold">{{ texts.shoppingcart.textTitle }}</p>
        </div>
        <!-- <shopping-cart-searchbar-layout :texts="texts"></shopping-cart-searchbar-layout> -->
        <shopping-cart-content-layout :products="products" :texts="texts"></shopping-cart-content-layout>
        <div style="height: 70px" />
        <div class="border-bottom" />
        <div style="height: 70px" />
      </div>
    </div>
    <footer-component />
    <delete-component />
    <whatsapp-component />
  </div>
</template>

<script>
export default {
  name: "ShoppingCartView",
  // props: {
  //   texts: {
  //     type: Object,
  //   },
  // },
  data() {
    return {
      //VARIABLES
      texts: "",
      products: [],
    };
  },
  beforeMount() {
    this.texts = FILE.shoppingcart[this.selectLanguage];
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  methods: {
    // getShoppingCart: function () {
    //   const payload = {};
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${this.$store.state.sToken}`,
    //     },
    //   };

    //   DB.get(
    //     `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`,
    //     config,
    //     payload
    //   )
    //     .then((response) => {
    //       this.products = response.data.results
    //         .map((e) => {

    //           return {
    //             ...e,
    //             originQuantity: e.aProducts[0].dQuantity,
    //           };
    //         });

    //       this.$store.commit("refresher", false);
    //     })
    //     .catch((error) => {
    //       this.mixError(error.response.data.message, error.response.status);
    //     });
    // },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.shoppingcart[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.text-title {
  text-align: left;
  font-size: 45px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
  margin-left: 5%;
}

.border-bottom {
  border-bottom: 1px solid #d5d5d5;
  width: 90%;
  margin-left: 5%;
}

.bg-container {
  width: 100%;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 64px;
}

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .text-title {
    text-align: center;
    font-size: 30px;
    letter-spacing: 0px;
    color: #151d27;
    opacity: 1;
    margin-left: 0%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */
</style>