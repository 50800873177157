var texts = {
  en: {
    paymentsprocess: {
      textDialogErrorOrder: {
        sTxtTitle: "Failed to buy",
        sTxtBtn: "Proceed to cart",
      },
      shopSupplier: "shop more from this supplier",
      message: {
        textPalletsOutOf: "pallets available from",
        textPalletsTotals: " total",
        text1: "Total pallets: ",
        text2: " pallets in transport number",
      },
      message2: {
        text1: "the order is distributed in ",
        text2: " transports",
      },
      modalTextConditionsPallets:
        "I have read and I agree with the distribution of my order <br/> in transport organized by PROPLAT.",
      modalTextTitle: "Authenticate your purchase",
      modalTextDescription:
        "By authenticating your purchase, you accept our terms and <br/> conditions and agree to make the corresponding payment.",
      modalTextTermsConditions1: "Accept the ",
      modalTextTermsConditions2: "terms and conditions",
      modalTextPassword: "Password",
      modalButtonCancel: "Cancel",
      modalButtonConfirm: "Confirm",
      titleStep1: "Warehouse",
      titleStep2: "Payment method",
      titleStep3: "Confirmation",
      textTotal: "Total",
      textCost1:
        "Freight costs, Customs costs and In-Out costs are approximate and will be updated throughout the order process.",
      textCost2: "Sale order",
      textCost3: "which can be consulted within the report",
      textCost4: "Record Sales Orders.",
      textFlete1: "You can also see our",
      textFlete2: "Freight tabulator.",
      textLocationWarehose: "Warehouse address",
      returnCart: "Back to shopping cart",
      buttonContinue: "Continue",
      buttonReturn: "Back",
      buttonFinalized: "Confirm order",
      textNoProvider: "There are no articles in the shopping cart",
      textProblemCart:
        "One of the items in the shopping cart has been modified, please review your shopping cart.",
    },
  },
  sp: {
    paymentsprocess: {
      textDialogErrorOrder: {
        sTxtTitle: "Error al comprar",
        sTxtBtn: "Proceder al carrito",
      },
      shopSupplier: "comprar más de este proveedor",
      message: {
        textPalletsOutOf: " tarimas disponibles de",
        textPalletsTotals: " totales",
        text1: "Total de paletas: ",
        text2: " pallets en el transporte número",
      },
      message2: {
        text1: "El pedido esta distribuido en ",
        text2: " transportes",
      },
      modalTextConditionsPallets:
        "He leído y estoy de acuerdo con la distribución de mi pedido <br/> en transporte organizado por PROPLAT.",
      modalTextTitle: "Autentica tu compra",
      modalTextDescription:
        "Al autenticar tu compra aceptas nuestros términos y <br/> condiciones y te comprometes a realizar el pago correspondiente.",
      modalTextTermsConditions1: "Aceptar los ",
      modalTextTermsConditions2: "Términos y condiciones",
      modalTextPassword: "Contraseña",
      modalButtonCancel: "Cancelar",
      modalButtonConfirm: "Confirmar",
      titleStep1: "Almacén",
      titleStep2: "Método de pago",
      titleStep3: "Confirmación",
      textTotal: "Total",
      textCost1:
        "Los costos de flete, costos de aduana y costos In-Out son aproximados y estos se actualizarán en el transcurso del proceso del pedido.",
      textCost2: "Pedido de Venta",
      textCost3: "el cual podrán consultar dentro del reporte",
      textCost4: "Histórico Pedidos de Venta.",
      textFlete1: "También podrá ver nuestro",
      textFlete2: "Tabulador de Fletes.",
      textLocationWarehose: "Dirección del almacén",
      returnCart: "Volver al carrito de compras",
      buttonContinue: "Continuar",
      buttonReturn: "Regresar",
      buttonFinalized: "Confirmar pedido",
      textNoProvider: "No hay articulos en el carrito",
      textProblemCart:
        "Uno de los artículos del carrito de compras ha sido modificado, favor de revisar de nuevo su pedido desde el carrito de compras.",
    },
  },
};

export default texts;
