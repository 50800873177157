const texts = {
  en: {
    rate: {
      textTitle: 'Qualify order',
      textDescription: 'Congratulations! Your order was successfully received, please help us by rating your vendor.',
      buttons: {
        close: 'Close',
        cancel: 'Cancel',
        send: 'Send',
      },
    },
  },
  sp: {
    rate: {
      textTitle: 'Calificar pedido',
      textDescription: '¡Felicidades! Tu pedido fué recibido satisfactoriamente, por favor ayúdanos calificando a nuestro proveeedor.',
      buttons: {
        close: 'Cerrar',
        cancel: 'Cancelar',
        send: 'Enviar',
      },
    },
  }
}
export default texts;