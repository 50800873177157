var texts = {
  en: {
    menu: {
      titleHome: 'Home',
      titleCatalog: 'Clasifications',
      catalog: {
        textCatalog: 'Categories',
        textSubcatalog: 'Subcategories',
        textArticle: 'Items'
      },
      titleWarehouse: 'Warehouses',
      titleProvider: 'Suppliers',
      titleOrder: 'Orders',
      order: {
        textInProcess: 'In process',
        textFinalized: 'Finalized',
        textInDispute: 'Disputed'
      },
      titleDisputeHistory: 'Disputes',
      disputeHistory: {
        textInProcess: 'In process',
        textFinalized: 'Finalized'
      },
      titleUserProfile: 'User profile',
      userProfile: {
        textUserData: 'User data',
        textEnterpriseData: 'Enterprise data',
        textAttachment: 'Attached documents',
        textBuyer: 'Users'
      },
      buttonLogin: 'Login',
      buttonLogout: 'Logout'
    }
  },
  sp: {
    menu: {
      titleHome: 'Inicio',
      titleCatalog: 'Clasificaciones',
      catalog: {
        textCatalog: 'Categorías',
        textSubcatalog: 'Subcategorías',
        textArticle: 'Artículos'
      },
      titleWarehouse: 'Almacenes',
      titleProvider: 'Proveedores',
      titleOrder: 'Pedidos',
      order: {
        textInProcess: 'En proceso',
        textFinalized: 'Finalizados',
        textInDispute: 'En disputa'
      },
      titleDisputeHistory: 'Disputas',
      disputeHistory: {
        textInProcess: 'En proceso',
        textFinalized: 'Finalizadas'
      },
      titleUserProfile: 'Perfil de usuario',
      userProfile: {
        textUserData: 'Datos de usuario',
        textEnterpriseData: 'Datos de empresa',
        textAttachment: 'Documentos adjuntos',
        textBuyer: 'Usuarios'
      },
      buttonLogin: 'Iniciar sesión',
      buttonLogout: 'Cerrar sesión'
    }
  }
};

export default texts;