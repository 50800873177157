var texts = {
  en: {
    changepassword: {
      buttonReturn: 'Back',
      textTitle: 'Change password',
      textDescription: 'Enter your new password to access the platform again.',
      password: {
        label: 'Password',
        placeholder: 'Password',
      },
      confirmPassword: {
        label: 'Confirm password',
        placeholder: 'Confirm password',
      },
      button: {
        changePassword: 'Send change',
      },
      messageSamePassword: 'Passwords must be the same',
    }
  },
  sp: {
    changepassword: {
      buttonReturn: 'Regresar',
      textTitle: 'Cambiar contraseña',
      textDescription: 'Ingresa tu nueva contraseña para acceder nuevamente a la plataforma.',
      password: {
        label: 'Contraseña',
        placeholder: 'Contraseña',
      },
      confirmPassword: {
        label: 'Confirmar contraseña',
        placeholder: 'Confirmar contraseña',
      },
      button: {
        changePassword: 'Enviar cambio',
      },
      messageSamePassword: 'Las contraseñas deben de ser iguales',
    }
  }
};

export default texts;