var texts = {
  en: {
    banner: {
      welcome: 'Welcome to PROPLAT',
      description: "The platform where you can buy PRODUCE <br/> directly, quickly, safely and without intermediaries."
    },
    catalogType: {
      titleSection: 'Category types',
      buttonViewCatalogs: 'See categories'
    },
    featuredSupplier: {
      titleSection: 'Featured suppliers',
      viewProviders: 'See all suppliers',
      textReview: 'Reviews',
      emptyText: 'There are no registered providers yet.',
      textFinishedOrders: 'Orders completed',
      textResponseTime: 'Response time',
    },
    itemSold: {
      titleSection: 'The best selling items',
      viewItems: 'See all items',
      buttonAddItem: 'Add',
      buttonViewItem: 'See',
      textReview: 'Reviews',
      emptyText: 'There are no registered items yet.'
    },
    popularCatalog: {
      titleSection: 'Popular categories',
      viewCatalogs: 'See all categories',
      emptyText: 'There are no categories registered yet.'
    }
  },
  sp: {
    banner: {
      welcome: 'Bienvenido a PROPLAT',
      description: "La plataforma donde puedes comprar PRODUCE <br/> de forma directa, rápida, segura y sin intermediarios. "
    },
    catalogType: {
      titleSection: 'Tipos de categorías',
      buttonViewCatalogs: 'Ver categorías'
    },
    featuredSupplier: {
      titleSection: 'Proveedores destacados',
      viewProviders: 'Ver todos los proveedores',
      textReview: 'Reseñas',
      emptyText: 'Aún no hay proveedores registrados.',
      textFinishedOrders: 'Pedidos concretados',
      textResponseTime: 'Tiempo de respuesta',
    },
    itemSold: {
      titleSection: 'Artículos más vendidos',
      viewItems: 'Ver todos los artículos',
      buttonAddItem: 'Añadir',
      buttonViewItem: 'Ver',
      textReview: 'Reseñas',
      emptyText: 'Aún no hay artículos registrados.'
    },
    popularCatalog: {
      titleSection: 'Categorías populares',
      viewCatalogs: 'Ver todos los categorías',
      emptyText: 'Aún no hay categorías registradas.'
    }
  }
};

export default texts;