<template>
  <v-app>
    <div id="app">
      <router-view />
    </div>
    <menu-component></menu-component>
    <v-dialog v-model="dialogKillSession" persistent :width="'50%'">
      <div class="global-dialog-delete">
        
        <p class="global-dialog-delete-title" >
          {{ "Session ended" }} 
        </p>
        <hr class="glogal-dialog-delete-hr" />
        <p class="global-dialog-delete-description">
          {{ "Your session has ended due to inactivity." }}
        </p>
        <div class="global-dialog-delete-footer">
          <v-btn class="global-button-modal-add" @click="exit">
            {{ "OK" }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      //VARIABLES
    };
  },
  methods: {
    exit() {
      localStorage.clear();
      this.$store.commit("setIsLogged", false);
      this.$store.commit("setFadeMenu", false);
      this.$store.commit("setToken", "");
      this.$store.commit("setFullName", "");
      this.$store.commit("setEnterpriseId", "");
      this.$store.commit("setUserId", "");
      this.$store.commit('setDialogKillSession',false)
        
      this.$router
        .push({
          path: "/login",
        })
        .catch(() => {});

      // this.$emit('emitClose')
      // // this.$router.push("/");
      // this.$store.commit("setToken", "");
      // this.$router.go();
    },
  },
  computed: {
    dialogKillSession() {
      return this.$store.state.dialogKillSession;
    },
  },
  watch: {
    dialogKillSession() {
      if (this.dialogKillSession) {
        this.texts = FILE.killSessionTexts[this.selectLanguage];
      }
    }
  }
};
</script>

<style>
/* import fonts */
@font-face {
  font-family: "montserrat-black";
  src: url("../src/assets/fonts/Montserrat-Black.ttf") format("opentype");
}
@font-face {
  font-family: "montserrat-bold";
  src: url("../src/assets/fonts/Montserrat-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "montserrat-light";
  src: url("../src/assets/fonts/Montserrat-Light.ttf") format("opentype");
}
@font-face {
  font-family: "montserrat-medium";
  src: url("../src/assets/fonts/Montserrat-Medium.ttf") format("opentype");
}
@font-face {
  font-family: "montserrat-regular";
  src: url("../src/assets/fonts/Montserrat-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "montserrat-semibold";
  src: url("../src/assets/fonts/Montserrat-SemiBold.ttf") format("opentype");
}

/* global styles */
html,
body {
  margin: 0;
  font-family: "monserrat-regular", sans-serif;
  position: relative;
}

.v-application {
  font-family: "monserrat-regular", sans-serif;
}

/* use fonts in the project through css classes */
.mon-black {
  font-family: "montserrat-black", sans-serif;
}

.mon-bold {
  font-family: "montserrat-bold", sans-serif;
}

.mon-light {
  font-family: "montserrat-light", sans-serif;
}

.mon-medium {
  font-family: "montserrat-medium", sans-serif;
}

.mon-regular {
  font-family: "montserrat-regular", sans-serif;
}

.mon-semibold {
  font-family: "montserrat-semibold", sans-serif;
}

/* add scrolls */
scrollbar {
  overflow: auto;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #f2f2f4;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #151d28;
}

@media (max-width: 960px) {
  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px transparent;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

/* inputs global style */
.global-inputs .v-text-field__details {
  display: none;
}

.global-inputs .v-input__slot {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 9px !important;
  font-size: 13px;
}

.global-inputs fieldset {
  border: 1px solid #e5e5e5 !important;
}

.global-inputs input {
  color: #000000 !important;
  letter-spacing: 0px !important;
}

.global-inputs .v-select__slot .v-label,
.global-inputs .v-text-field__slot .v-label {
  font-size: 13px;
}

/* Pagination Styles */
.pagination-styles .theme--light.v-pagination .v-pagination__item--active {
  color: #283c4d !important;
  font-size: 13px;
}

.pagination-styles .v-pagination__item,
.pagination-styles .v-pagination__navigation {
  height: 35px;
  width: 35px;
  font-size: 14px !important;
}

.pagination-styles {
  width: 310px;
}
/* global buttons */
.button-secondary {
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #283c4d;
  border-radius: 10px !important;
  opacity: 1 !important;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #283c4d !important;
  height: 40px !important;
  text-transform: initial !important;
  background-color: transparent !important;
}

.button-primary {
  height: 40px !important;
  text-transform: initial !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  opacity: 1 !important;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #283c4d !important;
}

.button-tertiary {
  height: 40px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #f50017 !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px !important;
  color: #f50017 !important;
  text-transform: initial !important;
  background-color: transparent !important;
}

.button-primary:disabled,
.button-secondary:disabled,
.button-tertiary:disabled {
  opacity: 0.5;
  cursor: no-drop !important;
  pointer-events: all !important;
}

/* texts calification style */
.text-calif {
  text-align: right;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif-num {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

/* empty text  */
.text-empty-home {
  text-align: left;
  letter-spacing: 0px;
  color: #bababa;
  opacity: 1;
  font-size: 20px;
  margin-bottom: 0;
}

/* align text clases */

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.width-90 {
  width: 90%;
}

.v-container-fluid {
  max-width: 90% !important;
}

@media (max-width: 960px) {
  .v-container-fluid {
    max-width: 100% !important;
  }
}

.f-s10 {
  font-size: 10px !important;
}

.f-s12 {
  font-size: 12px !important;
}

.f-s13 {
  font-size: 13px !important;
}

.f-s14 {
  font-size: 14px !important;
}

.f-s16 {
  font-size: 16px !important;
}

.f-s18 {
  font-size: 18px !important;
}

.f-s20 {
  font-size: 20px !important;
}

.f-s22 {
  font-size: 22px !important;
}

.f-s24 {
  font-size: 24px !important;
}

.f-s25 {
  font-size: 25px !important;
}

.f-s26 {
  font-size: 26px !important;
}

.f-s30 {
  font-size: 30px !important;
}

.f-s35 {
  font-size: 35px !important;
}

.f-s43 {
  font-size: 43px !important;
}

.f-s45 {
  font-size: 45px !important;
}

.f-s50 {
  font-size: 50px !important;
}

.f-s55 {
  font-size: 55px !important;
}

.color000000 {
  color: #000000 !important;
}

.colorffffff {
  color: #ffffff !important;
}

.colorF2F2F4 {
  color: #f2f2f4 !important;
}
.backgroundF2F2F4 {
  background-color: #F2F2F4 !important;
}
.background3194FF {
  background-color: #dfeeff !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: 900 !important;
  color: black !important;
  font-family: "montserrat-bold" !important;
}

.global-dialog-delete {
  /* width: 355px; */
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14);
  /* border-top: 6px solid #ff5b5b; */
  padding: 25px;
  text-align: center;
}

.global-dialog-delete-title {
  color: #292929;
  font-family: "mon", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.48px;
  /* text-transform: capitalize; */
}

.global-dialog-delete-description {
  color: #292929;
  font-family: "mon", sans-serif;
  font-size: 14px;
  letter-spacing: -0.34px;
  margin-top: 5px;
}

.glogal-dialog-delete-hr, .glogal-dialog-add-hr {
  border: 1px solid #292929;
}

.global-dialog-delete-footer, .global-dialog-add-footer {
  margin-top: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.global-button-modal-add {
  height: 45px !important;
  text-transform: none !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 13px !important;
  opacity: 1;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  padding-left: 35px !important;
  padding-right: 35px !important;
}

</style>
