<template>
  <v-container class="mt-5">
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-skeleton-loader
            :loading="isLoading"
            type="card, article, image"
        >
          <template v-slot:default>
            <v-card class="pa-5">
              <v-row no-gutters>
                <v-col cols="12" class="pa-4">
                  <v-row align="center" justify="center" class="mb-5">
                    <v-icon color="#f0be44" size="200">
                      mdi-shield-lock-outline
                    </v-icon>
                  </v-row>
                  <p class="text-center mt-2">
                    {{ texts.settings.title }}
                  </p>
                  <h2 class="text-center">{{ texts.settings.subTitle }}</h2>
                  <p class="text-center">
                    {{ texts.settings.subTitle2 }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-card class="pa-4" outlined>
                    <v-card-title class="headline text-center justify-center align-center">
                      <v-icon size="70" color="#f0be44">mdi-email</v-icon>
                    </v-card-title>
                    <v-card-title class="headline text-center justify-center align-center">
                      {{ texts.settings.email }}
                    </v-card-title>
                    <v-card-text class="text-center">
                      {{ texts.settings.emailDescription }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="#F0BE43" outlined @click="openDialog" block>
                        {{ texts.settings.configure }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card class="pa-4" outlined>
                    <v-card-title class="headline text-center justify-center align-center">
                      <v-icon size="70" color="#f0be44">mdi-apps</v-icon>
                    </v-card-title>
                    <v-card-title class="headline text-center justify-center align-center">
                      {{ texts.settings.authenticator }}
                    </v-card-title>
                    <v-card-text class="text-center">
                      {{ texts.settings.authenticatorDescription }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn disabled color="primary" block> {{ texts.settings.configure }}</v-btn> 
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card class="pa-4" outlined>
                    <v-card-title class="headline text-center justify-center align-center">
                      <v-icon size="70" color="#f0be44">mdi-message-text</v-icon>
                    </v-card-title>
                    <v-card-title class="headline text-center justify-center align-center">
                      {{ texts.settings.sms }}
                    </v-card-title>
                    <v-card-text class="text-center">
                      {{ texts.settings.smsDescription }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn disabled color="primary" block> {{ texts.settings.configure }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- Dialogo para configuración inicial -->
    <v-dialog v-model="configDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          {{ isEmailRegistered ? texts.settings.editEmail : texts.settings.configureEmail }}   
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm" v-model="editValid">
            <v-text-field
                v-model="email"
                :rules="emailRules"
                :label="texts.settings.email" 
                required
                outlined
                :disabled="isEmailRegistered"
                prepend-icon="mdi-email"
                type="email"
            ></v-text-field>
            <v-text-field
                v-if="isEmailRegistered"
                v-model="newEmail"
                :rules="emailRules"
                :label="texts.settings.newEmail" 
                required
                outlined
                prepend-icon="mdi-email"
                type="email"
            ></v-text-field>
            <v-text-field
                v-if="!isEmailRegistered"
                v-model="password"
                :rules="passwordRules"
                :label="texts.settings.password" 
                required
                :disabled="!email || !emailRules.every((rule) => rule(email) === true)"
                outlined
                prepend-icon="mdi-lock"
                type="password"
            ></v-text-field>
            <v-otp-input
                v-model="otp"
                v-if="isEmailRegistered"
                type="number"
                color="#f0be43"
                length="6"
            ></v-otp-input>
            <div v-if="isEmailRegistered">
              <span>{{ texts.settings.confirmEmail }}</span> 
            </div>
            <div v-else>
              <span>{{ texts.settings.confirmPassword }}</span> 
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="configDialog = false">{{ texts.modalForm.cancel }}</v-btn>
          <v-btn color="blue darken-1" :loading="isFormLoading" text @click="confirmConfig"
                 :disabled="!editValid || (isEmailRegistered && !otp.length === 6)">
            {{ isEmailRegistered ? texts.settings.sendCode : texts.settings.configure }} 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para actualización de correo -->
    <v-dialog v-model="editDialog" max-width="500px" persistent>
      <v-card>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1" color="#f0be44">
              {{ texts.settings.newEmailConfig }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" color="#f0be44">
              {{ texts.settings.otpCode }}   
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card-text>
                <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    :label="texts.settings.currentEmail" 
                    required
                    outlined
                    disabled
                    prepend-icon="mdi-email"
                    type="email"
                    @keydown.native="preventSubmit"
                ></v-text-field>
                <v-form ref="editForm" v-model="editValid">
                  <v-text-field
                      v-model="newEmail"
                      :rules="emailRules"
                      :label="texts.settings.newEmail" 
                      required
                      outlined
                      prepend-icon="mdi-email"
                      type="email"
                      @keydown.native="preventSubmit"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#f0be44" text @click="editDialog = false">{{ texts.modalForm.cancel }}</v-btn>  
                <v-btn color="#f0be44" text @click="sendOtp" :disabled="!editValid">{{ texts.modalForm.continue }}</v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card-text>
                <v-form ref="otpForm" v-model="otpValid">
                  <v-otp-input
                      v-model="otp"
                      :rules="otpRules"
                      type="number"
                      length="6"
                      @keydown.native="preventSubmit"
                  ></v-otp-input>
                </v-form>
                <div>
                  <span>{{ texts.settings.confirmEmail }}</span> 
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#f0be44" text @click="step = 1">{{ texts.modalForm.cancel }}</v-btn> 
                <v-btn color="#f0be44" text @click="validateOtp" :loading="isFormLoading" :disabled="!otpValid"> {{ texts.modalForm.validate }} </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      newEmail: "",
      valid: false,
      step: 1,
      isEmailRegistered: false,
      isLoading: true,
      isFormLoading: false,
      texts: {},
      emailRules: [
        (v) => !!v || "The email is required",
        (v) => /.+@.+\..+/.test(v) || "The email must be valid"
      ],
      configDialog: false,
      editDialog: false,
      password: "",
      otp: "",
      editValid: false,
      otpValid: false,
      otpSent: false,
      passwordRules: [
        (v) => !!v || "The password is required", 
        (v) => v.length >= 6 || "The password must have at least 6 characters"
      ],
      otpRules: [
        (v) => !!v || "The OTP code is required", 
        (v) => /^\d{6}$/.test(v) || "The OTP code must be a 6-digit number"
      ],
    };
  },
  methods: {
    getDataAuth() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/getData`, {
        headers: {
          Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
        }
      }).then((response) => {
        if (!response.data.success) {
          this.mixError(response.data.message, response.status);
        }
        this.email = response.data.data.sAuthEmail.sEmail;
        this.isEmailRegistered = response.data.data.sAuthEmail.bEnabled;
        this.resetForm();
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    openDialog() {
      if (this.isEmailRegistered) {
        this.openEditModal();
      } else {
        this.configDialog = true;
      }
    },
    activate2FA() {
      if (this.$refs.form.validate()) {
        console.log("Activando 2FA para:", this.email);
        this.isEmailRegistered = true;
      }
    },
    openEditModal() {
      this.editDialog = true;
      this.step = 1;
    },
    sendOtp() {
      if (this.$refs.editForm.validate()) {
        if (this.newEmail === this.email) {
          this.mixError(this.texts.settings.newEmailError, 400);
          return;
        }
        this.updateEmailOpt(this.newEmail);
        this.step = 2;
      }
    },
    validateOtp() {
      if (this.$refs.otpForm.validate()) {
        console.log('edit modal', this.newEmail, this.otp);
        this.updateEmail(this.newEmail, this.otp);
      }
    },
    confirmConfig() {
      if (this.$refs.editForm.validate()) {
        this.isFormLoading = true;
        const payload = {
          sEmail: this.email,
          sPassword: this.password
        };
        DB.post(`${URI}/api/v1/${this.selectLanguage}/authenticator/saveEmail`, payload, {
          headers: {
            Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
          }
        }).then((response) => {
          if (!response.data.success) {
            this.mixError(response.data.message, response.status);
          }
          this.mixSuccess(response.data.message, response.status);
          this.isFormLoading = false;
          this.getDataAuth();
          this.configDialog = false;
        }).catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.isFormLoading = false;
        });
      }
    },
    updateEmailOpt(newEmail) {
      console.log('Sending OTP to:', this.email);
      DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
        headers: {
          Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
        }
      }).then((response) => {
        if (!response.data.success) {
          this.mixError(response.data.message, response.status);
        }
      }).catch((error) => {
        this.mixError(error.response.data.message, error.response.status);
        this.isFormLoading = false;
      });
    },
    updateEmail(newEmail, otp) {
      this.isFormLoading = true;
      console.log('Updating email to:', newEmail, 'with OTP:', otp);
      try {
        DB.put(`${URI}/api/v1/${this.selectLanguage}/authenticator/updateEmail`, {
          sEmail: newEmail,
          sCode: otp
        }, {
          headers: {
            Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
          }
        }).then((response) => {
          console.log('response', response.data);
          if (!response.data.success) {
            this.mixError(response.data.message, response.status);
          }
          this.mixSuccess(response.data.message, response.status);
          this.getDataAuth();
          this.isFormLoading = false;
          this.editDialog = false;
        }).catch((error) => {
          this.isFormLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
      } catch (error) {
        this.isFormLoading = false;
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    preventSubmit(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
    resetForm() {
      this.newEmail = '';
      this.password = '';
      this.otp = '';
    }
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getDataAuth();
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    }
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

p {
  font-size: 16px;
}
</style>
