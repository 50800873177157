var texts = {
  en: {
    delete: {
      textTitle: 'Delete ',
      textButtonDelete: 'Delete',
      textButtonCancel: 'Cancel',
      textDescription: 'Are you sure to delete this item? By performing this action, it cannot be reversed.'
    }
  },
  sp: {
    delete: {
      textTitle: 'Eliminar ',
      textButtonDelete: 'Eliminar',
      textButtonCancel: 'Cancelar',
      textDescription: '¿Estás seguro de eliminar este elemento? Al realizar esta acción no podrá revertirse.'
    }
  }
};

export default texts;