var texts = {
  en: {
    input: {
      searchText: 'Search an item',
    },
    buttons: {
      loginText: 'Login',
      signupText: 'Signup'
    }
  },
  sp: {
    input: {
      searchText: 'Buscar un artículo',
    },
    buttons: {
      loginText: 'Iniciar sesión',
      signupText: 'Registrarse'
    }
  }
};

export default texts;