var texts = {
  en: {
    signinVendor: {
      buttonReturn: 'Back',
      textTitle: 'Supplier registration',
      name: {
        label: 'Name',
        placeholder: 'Name',
      },
      lastname: {
        label: 'Last name',
        placeholder: 'Last name',
      },
      email: {
        label: 'Email',
        placeholder: 'Email'
      },
      phone: {
        label: 'Phone number',
        placeholder: 'Phone number',
      },
      enterpriseName: {
        label: 'Enterprise name',
        placeholder: 'Enterprise name',
      },
      country: {
        label: 'Country',
        placeholder: 'Country',
      },
      state: {
        label: 'State',
        placeholder: 'State',
      },
      password: {
        label: 'Password',
        placeholder: '●●●●●●●●',
      },
      confirmPassword: {
        label: 'Confirm password',
        placeholder: '●●●●●●●●',
      },
      buttonCancel: 'Cancel',
      buttonRegister: 'Register',
      messageErrorPassword: 'Passwords must be the same',
    }
  },
  sp: {
    signinVendor: {
      buttonReturn: 'Regresar',
      textTitle: 'Registro de vendedor',
      name: {
        label: 'Nombre',
        placeholder: 'Nombre',
      },
      lastname: {
        label: 'Apellido',
        placeholder: 'Apellido',
      },
      email: {
        label: 'Correo electrónico',
        placeholder: 'Correo electrónico'
      },
      phone: {
        label: 'Número teléfonico',
        placeholder: 'Número teléfonicoEnterprise name',
      },
      enterpriseName: {
        label: 'Nombre de la empresa',
        placeholder: 'Nombre de la empresa',
      },
      country: {
        label: 'País',
        placeholder: 'País',
      },
      state: {
        label: 'Estado',
        placeholder: 'Estado',
      },
      password: {
        label: 'Contraseña',
        placeholder: '●●●●●●●●',
      },
      confirmPassword: {
        label: 'Confirmar contraseña',
        placeholder: '●●●●●●●●',
      },
      buttonCancel: 'Cancelar',
      buttonRegister: 'Registrar',
      messageErrorPassword: 'Las contraseñas deben de ser iguales',
    }
  }
};

export default texts;