<template>
  <div>
    <div
      class="container-login"
      :style="{height: screenHeight + 'px'}"
    >
      <div class="content-left">
        <div class="scrollable-screen">
          <signin-customer-form-layout :texts="texts" />
        </div>
        <select-language-component />
      </div>
      <div class="content-right">
        <signin-customer-background-image-layout />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SigninCustomerView",
  data() {
    return {
      //VARIABLES
      screenHeight: 0,
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.signincustomer[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.signincustomer[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.scrollable-screen {
  overflow: auto;
  height: 100%;
  /* overflow-x: hidden; */
}

.scrollable-screen::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
.scrollable-screen::-webkit-scrollbar-thumb {
  background: transparent;
}

.scrollable-screen::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-screen {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.container-login {
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
}

.content-left {
  width: 45%;
  height: 100%;
  padding: 25px;
  position: relative;
}

.content-right {
  width: 55%;
  height: 100%;
}

@media (min-width: 601px) and (max-width: 960px) {
  .content-left {
    width: 52%;
  }

  .content-right {
    width: 48%;
  }
}

@media (max-width: 720px) {
  .content-left {
    width: 100%;
    padding: 10px;
  }
  .content-right {
    display: none;
  }
}
</style>