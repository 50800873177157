var texts = {
  en: {
    headersProducto: [
      { text: "Product", value: "sProductName", align: "center" },
      { text: "Boxes", value: "dQuantity", align: "center" },
      { text: "Boxes per pallet", value: "dBoxesPerPallet", align: "center" },
      { text: "Price in USD", value: "dUnitPriceUSD", align: "center" },
      { text: "Logistic cost", value: "sLogisticCost", align: "center" },
      { text: "Price per box", value: "sTotalPerBox", align: "center" },
      { text: "Total with logistic", value: "sTotal", align: "center" },
    ],
    breakDownCosts: 'Breakdown of cost',
    headersComisiones: [
      {
        text: "Name",
        value: "sFeeName",
        cellClass: "mon-regular f-s12 color000000",
        class: "f-s14",
      },
      {
        text: "Commission Subtotal",
        value: "dTotal",
        cellClass: "mon-bold f-s12 color000000  background3194FF",
        class: "mon-bold f-s14 background3194FF",
        align: "center",
      },
    ],
  },
  sp: {
    headersProducto: [
      { text: "Producto", value: "sProductName", align: "center" },
      { text: "Cajas", value: "dQuantity", align: "center" },
      { text: "Cajas por pallet", value: "dBoxesPerPallet", align: "center" },
      { text: "Precio USD", value: "dUnitPriceUSD", align: "center" },
      { text: "Costo de in&Out", value: "dTotalInOutPerBox", align: "center" },
      { text: "Costo Logistico", value: "sLogisticCost", align: "center" },
      { text: "Precio por caja", value: "sTotalPerBox", align: "center" },
      { text: "Total con costo operacional", value: "sTotal", align: "center" },
    ],
    breakDownCosts: 'Desglose de costos',
    headersComisiones: [
      {
        text: "Nombre",
        value: "sFeeName",
        cellClass: "mon-regular f-s12 color000000",
        class: "f-s14",
      },
      {
        text: "Subtotal de comisiones",
        value: "dTotal",
        cellClass: "mon-bold f-s12 color000000  background3194FF",
        class: "mon-bold f-s14 background3194FF",
        align: "center",
      },
    ],
  }
};

export default texts;