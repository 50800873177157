var texts = {
  en: {
    order: {
      textDay: 'Day',
      emptyText: 'At the moment there is no information to display',
      textTitle: 'Orders',
      textTab1: 'In process',
      textTab2: 'Finalized',
      textTab3: 'Disputed',
      tableProcess: {
        headers: [
          {
            text: 'Sales order',
            align: 'text-left'
          },
          {
            text: 'Estimated border delivery',
            align: 'text-left'
          },
          {
            text: 'Provider',
            align: 'text-left'
          },
          {
            text: 'Total',
            align: 'text-left'
          },
          {
            text: 'Invoice expiration',
            align: 'text-center'
          },
          {
            text: 'Payment',
            align: 'text-left'
          },
          {
            text: 'Status',
            align: 'text-left'
          },
          {
            text: 'Actions',
            align: 'text-center'
          }
        ]
      },
      tableFinalized: {
        headers: [
          {
            text: 'Sales order',
            align: 'text-left'
          },
          // {
          //   text: 'Delivery',
          //   align: 'text-left'
          // },
          {
            text: 'Supplier',
            align: 'text-left'
          },
          {
            text: 'Total',
            align: 'text-left'
          },
          {
            text: 'Invoice expiration',
            align: 'text-center'
          },
          {
            text: 'Payment',
            align: 'text-left'
          },
          {
            text: 'Status',
            align: 'text-left'
          },
          {
            text: 'Invoice',
            align: 'text-left'
          },
          {
            text: 'Actions',
            align: 'text-center'
          },
        ]
      },
      tableInDispute: {
        headers: [
          {
            text: 'Sales order',
            align: 'text-left',
          },
          {
            text: 'Delivery',
            align: 'text-left',
          },
          {
            text: 'Supplier',
            align: 'text-left',
          },
          {
            text: 'Total',
            align: 'text-left',
          },
          {
            text: 'Payment',
            align: 'text-left',
          },
          {
            text: 'Status',
            align: 'text-left',
          },
          {
            text: 'Actions',
            align: 'text-center',
          },
        ]
      },
      filters: {
        textSearch: 'Search sales order',
        startDate: 'Start date',
        endDate: 'End date',
        status: 'Status',
        // paymentStatus: 'Payment status',
        textLabelStatus: 'Status',
        textLabelStatusPaid: 'Payment states',
        paymentStatusPaid: 'Paid',
        paymentStatusToPaid: 'To pay',
        orderStatus: [
          {
            value: "247b4a0b-8796-4b59-83f6-84043b1a5472",
            name: "Order received",
          },
          {
            value: "067590d6-f90d-4308-8c59-72840bdfa085",
            name: "in preparation",
          },
          {
            value: "939ec62b-5060-4079-aadf-282e6abba399",
            name: "In transit",
          },
          {
            value: "f6970943-c9d3-4935-97ad-665968544c2e",
            name: "In the process of border crossing",
          },
          {
            value: "227d8762-e17a-4d85-b372-0b78ea11ee34",
            name: "Successful crossover",
          },
          {
            value: "b7b6ccfa-23cf-44fc-871d-be29e7777a83",
            name: "Warehouse border",
          },
          {
            value: "ea1a9952-a6ac-4931-8a95-9ad62fcdb33b",
            name: "Accepted at border warehouse",
          },
          {
            value: "1cb2626a-1cb1-4bb7-ac55-63a5b754c725",
            name: "Transferred",
          },
          {
            value: "130afe3b-178b-4e89-bf74-7b42ad99b948",
            name: "On delivery route",
          },
          {
            value: "e79ef080-50d8-4aa6-a198-58a491c8a772",
            name: "Delivered",
          },
        ]
      }
    },

  },
  sp: {
    order: {
      textDay: 'Día',
      emptyText: 'Por el momento no hay información para mostrar',
      textTitle: 'Pedidos',
      textTab1: 'En proceso',
      textTab2: 'Finalizados',
      textTab3: 'En disputa',
      tableProcess: {
        headers: [
          {
            text: 'Pedido de venta',
            align: 'text-left'
          },
          {
            text: 'Fecha estimada de entrega en frontera',
            align: 'text-left'
          },
          {
            text: 'Proveedor',
            align: 'text-left'
          },
          {
            text: 'Total',
            align: 'text-left'
          },
          {
            text: 'Venc. de factura',
            align: 'text-center'
          },
          {
            text: 'Pago',
            align: 'text-left'
          },
          {
            text: 'Estado',
            align: 'text-left'
          },
          {
            text: 'Acciones',
            align: 'text-center'
          },
        ]
      },
      tableFinalized: {
        headers: [
          {
            text: 'Pedido de venta',
            align: 'text-left'
          },
          // {
          //   text: 'Entrega',
          //   align: 'text-left'
          // },
          {
            text: 'Proveedor',
            align: 'text-left'
          },
          {
            text: 'Total',
            align: 'text-left'
          },
          {
            text: 'Venc. de factura',
            align: 'text-center'
          },
          {
            text: 'Pago',
            align: 'text-left'
          },
          {
            text: 'Estado',
            align: 'text-left'
          },
          {
            text: 'Factura',
            align: 'text-left'
          },
          {
            text: 'Acciones',
            align: 'text-center'
          },
        ]
      },
      tableInDispute: {
        headers: [
          {
            text: 'Pedido de venta',
            align: 'text-left'
          },
          {
            text: 'Entrega',
            align: 'text-left'
          },
          {
            text: 'Proveedor',
            align: 'text-left'
          },
          {
            text: 'Total',
            align: 'text-left'
          },
          {
            text: 'Pago',
            align: 'text-left'
          },
          {
            text: 'Estado',
            align: 'text-left'
          },
          {
            text: 'Acciones',
            align: 'text-center'
          },
        ]
      },
      filters: {
        textSearch: 'Buscar pedido de venta',
        startDate: 'Fecha inicio',
        endDate: 'Fecha final',
        status: 'Estado',
        // paymentStatus: 'Estado de pago',
        textLabelStatus: 'Estado',
        textLabelStatusPaid: 'Estado de pago',
        paymentStatusPaid: 'Pagado',
        paymentStatusToPaid: 'Por pagar',
        orderStatus: [
          {
            value: "247b4a0b-8796-4b59-83f6-84043b1a5472",
            name: "Pedido Recibido",
          },
          {
            value: "067590d6-f90d-4308-8c59-72840bdfa085",
            name: "En preparación",
          },
          {
            value: "939ec62b-5060-4079-aadf-282e6abba399",
            name: "En tránsito",
          },
          {
            value: "f6970943-c9d3-4935-97ad-665968544c2e",
            name: "En proceso de cruce fronterizo",
          },
          {
            value: "227d8762-e17a-4d85-b372-0b78ea11ee34",
            name: "Cruce exitoso",
          },
          {
            value: "b7b6ccfa-23cf-44fc-871d-be29e7777a83",
            name: "Almacén frontera",
          },
          {
            value: "ea1a9952-a6ac-4931-8a95-9ad62fcdb33b",
            name: "Aceptado en almacén frontera",
          },
          {
            value: "1cb2626a-1cb1-4bb7-ac55-63a5b754c725",
            name: "Transferido",
          },
          {
            value: "130afe3b-178b-4e89-bf74-7b42ad99b948",
            name: "En ruta de entrega",
          },
          {
            value: "e79ef080-50d8-4aa6-a198-58a491c8a772",
            name: "Entregado",
          },
        ]
      }
    },

  }
};

export default texts;