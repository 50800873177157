<template>
  <div>
    <navbar-component />
    <div class="bg-container">
      <add-warehouse-searchbar-layout :texts="texts"></add-warehouse-searchbar-layout>
      <add-warehouse-content-layout :texts="texts"></add-warehouse-content-layout>
    </div>
    <footer-component />
    <whatsapp-component />
  </div>
</template>

<script>
export default {
  name: "AddWarehouseView",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.warehouse[this.selectLanguage];
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.warehouse[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 60px;
}
</style>