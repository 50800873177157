<template>
  <div>
    <navbar-component />
    <div class="bg-container">
      <order-detail-content-layout :texts="texts"></order-detail-content-layout>
    </div>
    <footer-component />
    <delete-component />
    <create-dispute-component />
    <gallery-component />
    <order-detail-rate-layout />
    <whatsapp-component />
  </div>
</template>

<script>
export default {
  name: "OrderDetailView",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.orderdetail[this.selectLanguage];
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.orderdetail[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 75px;
}
</style>