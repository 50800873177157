var texts = {
  en: {
    passwordrecovery: {
      buttonReturn: 'Back',
      textTitle: 'Password recovery',
      textDescription: 'Enter the email registered in PROPLAT to be able to indicate the steps to follow for the recovery.',
      email: {
        label: 'Email',
        placeholder: 'Email'
      },
      buttonSend: 'Send a token for recovery'
    }
  },
  sp: {
    passwordrecovery: {
      buttonReturn: 'Regresar',
      textTitle: 'Recuperar contraseña',
      textDescription: 'Ingresa el correo electrónico registrado en PROPLAT para poder indicarte los pasos a seguir para la recuperación.',
      email: {
        label: 'Correo electrónico',
        placeholder: 'Correo electrónico'
      },
      buttonSend: 'Enviar recuperación'
    }
  }
};

export default texts;