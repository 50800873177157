var texts = {
  en: {
    login: {
      textTitle: 'Login',
      inputEmail: {
        label: 'Email',
        placeholder: 'Email'
      },
      inputPassword: {
        label: 'Password',
        placeholder: '●●●●●●●●'
      },
      buttonLogin: 'Login',
      forgotPassword: "Do you have a problem logging in?",
      proplatAccount: 'Create account',
      buttonReturn: 'Back'
    }
  },
  sp: {
    login: {
      textTitle: 'Inicio de sesión',
      inputEmail: {
        label: 'Correo electrónico',
        placeholder: 'Correo electrónico'
      },
      inputPassword: {
        label: 'Contraseña',
        placeholder: '●●●●●●●●'
      },
      buttonLogin: 'Iniciar sesión',
      forgotPassword: '¿Problemas para iniciar sesión?',
      proplatAccount: 'Crear cuenta',
      buttonReturn: 'Regresar'
    }
  }
};

export default texts;