import home from "@/texts/home";
import landing from "@/texts/landing";
import navbar from "@/texts/navbar";
import footer from "@/texts/footer";
import menu from "@/texts/menu";
import login from "@/texts/login";
import catalog from "@/texts/catalog";
import subcatalog from "@/texts/subcatalog";
import article from "@/texts/article";
import viewarticle from "@/texts/viewarticle";
import signin from "@/texts/signin";
import signincustomer from "@/texts/signincustomer";
import signinvendor from "@/texts/signinvendor";
import passwordrecovery from "@/texts/passwordrecovery";
import profile from "@/texts/profile";
import changepassword from "@/texts/changepassword";
import warehouse from "@/texts/warehouse";
import shoppingcart from "@/texts/shoppingcart";
import deletee from "@/texts/deletee";
import order from "@/texts/order";
import notificationcart from "@/texts/notificationcart";
import vendor from "@/texts/vendor";
import paymentprocess from "@/texts/paymentprocess";
import disputes from "@/texts/disputes";
import orderdetail from "@/texts/orderdetail";
import createdispute from "@/texts/createdispute";
import disputedetail from "@/texts/disputedetail"
import rate from "@/texts/rate"
import superbuyer from "@/texts/superbuyer"
import takeRate from "@/texts/takeRate"

export const texts = {
  home,
  navbar,
  footer,
  menu,
  login,
  catalog,
  subcatalog,
  article,
  viewarticle,
  signin,
  signincustomer,
  signinvendor,
  passwordrecovery,
  profile,
  changepassword,
  warehouse,
  shoppingcart,
  deletee,
  order,
  notificationcart,
  vendor,
  paymentprocess,
  disputes,
  orderdetail,
  createdispute,
  disputedetail,
  rate,
  landing,
  superbuyer,
  takeRate
}