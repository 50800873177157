<template>
  <div>
    <navbar-component />
    <div class="bg-container">
      <dispute-detail-content-layout :texts="texts" />
    </div>
    <footer-component />
    <whatsapp-component />

  </div>
</template>

<script>
export default {
  name: "DisputeDetailView",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.disputedetail[this.selectLanguage];
  },
  methods: {},
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.disputedetail[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 60px;
}
</style>