var texts = {
  en: {
    footer: {
      description: 'PROPLAT, the platform where you can buy PRODUCE directly, quickly, safely and without intermediaries.',
      termsAndConditions: 'Terms & Conditions',
      allRights: 'All Rights Reserved PROPLAT',
      poweredBy: 'Powered by Sofex Technologies'
    }
  },
  sp: {
    footer: {
      description: 'PROPLAT, la plataforma donde puedes comprar PRODUCE de forma directa, rápida, segura y sin intermediarios.',
      termsAndConditions: 'Términos y condiciones',
      allRights: 'Todos los derechos reservados PROPLAT',
      poweredBy: 'Creado por Sofex Technologies'
    }
  }
};

export default texts;